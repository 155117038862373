
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.red {
    color: #e35e6b;
}

.v-middle {
    vertical-align: middle;
}

.msg-success {
    background-color: #28a745;
    color: #fff;
}

.msg-error {
    background-color: #e35e6b;
    color: #fff;
}

::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}
::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
  background: #dad7d7;
}
